import * as React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { OrganizationInfoType } from 'reducers/organizationSlice';
import { RootState } from 'store';
import { Links } from 'types/links';

export const useCheckProvidedOrg = (): Pick<
  OrganizationInfoType,
  'name' | 'registrationAllowed'
> => {
  const router = useRouter();
  const { name, registrationAllowed } = useSelector<
    RootState,
    OrganizationInfoType
  >(({ organization }) => organization);

  React.useEffect(() => {
    if (!name) {
      router.push(Links.organization);
    }
  }, [name, router]);

  return { name, registrationAllowed };
};

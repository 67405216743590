import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Control, Controller } from 'react-hook-form';
import Input from 'ui-kit/input';
import clsx from 'clsx';

import LocalizedDatePicker from 'ui-kit/date-picker';
import Select from 'ui-kit/select';
import { Option } from 'ui-kit/select/type';

import {
  RegistrationInputNamesEnum,
  RegistrationValuesType,
} from 'types/registration';

import { RootState } from 'store';

import styles from './registration.module.scss';
import { OrganizationInfoType } from 'reducers/organizationSlice';
import { useGetOrganizationQuery } from 'store/api';

type FormFieldsProps = {
  control: Control<RegistrationValuesType, object>;
};

const FormFields: FC<FormFieldsProps> = ({ control }) => {
  const { name, unrequiredFields } = useSelector<
    RootState,
    OrganizationInfoType
  >(({ organization }) => organization);
  const { data } = useGetOrganizationQuery(name ?? '', {
    skip: !name,
  });

  const regionsOptions: Option[] = useMemo(() => {
    if (data && data.regions) {
      return data.regions.map((region) => ({
        label: region,
        value: region,
      }));
    }

    return [];
  }, [data]);

  const departmentsOptions: Option[] = useMemo(() => {
    if (data && data.departments) {
      return data.departments.map((department) => ({
        label: department,
        value: department,
      }));
    }

    return [];
  }, [data]);

  return (
    <>
      <Controller
        name={RegistrationInputNamesEnum.firstName}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            placeholder="Имя"
            label="Имя"
            readonly={unrequiredFields?.includes(field.name)}
            className={styles.input}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.secondName}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            placeholder="Фамилия"
            label="Фамилия"
            readonly={unrequiredFields?.includes(field.name)}
            className={styles.input}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.email}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            placeholder="Email"
            type="email"
            label="Email"
            readonly={unrequiredFields?.includes(field.name)}
            className={clsx(styles.input, styles.email)}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.birthDate}
        control={control}
        render={({ field: { onChange, value, name }, fieldState }) => (
          <LocalizedDatePicker
            label="Дата рождения"
            readonly={unrequiredFields?.includes(name)}
            className={styles.input}
            fieldState={fieldState}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.position}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            placeholder="Ваша должность"
            label="Должность"
            readonly={unrequiredFields?.includes(field.name)}
            className={styles.input}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.department}
        control={control}
        render={({ field, fieldState }) => (
          <Select
            options={departmentsOptions}
            placeholder="Подразделение"
            label="Подразделение"
            disabled={unrequiredFields?.includes(field.name)}
            className={styles.input}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.location}
        control={control}
        render={({ field, fieldState }) => (
          <Select
            options={regionsOptions}
            placeholder="Регион"
            label="Регион"
            disabled={unrequiredFields?.includes(field.name)}
            className={styles.input}
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.password}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            type="password"
            placeholder="password"
            label="Введите пароль"
            className={styles.input}
            autoComplete="new-password"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.confirmPassword}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            type="password"
            placeholder="password"
            label="Повторите пароль"
            className={styles.input}
            autoComplete="new-password"
            fieldState={fieldState}
            {...field}
          />
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.termsOfService}
        control={control}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              id="tos"
              className={clsx(styles.checkbox, {
                [styles.checkboxError]: error,
              })}
              value={String(value)}
              {...field}
            />
            <label className={styles.checkboxLabel} htmlFor="tos">
              Я подтверждаю, что ознакомился с{' '}
            </label>
            <a
              href="/files/Maven_terms_of_service_2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}>
              условиями использования
            </a>
          </div>
        )}
      />
      <Controller
        name={RegistrationInputNamesEnum.privacyPolicy}
        control={control}
        render={({ field: { value, ...field }, fieldState: { error } }) => (
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              id="privacy-policy"
              className={clsx(styles.checkbox, {
                [styles.checkboxError]: error,
              })}
              value={String(value)}
              {...field}
            />
            <label className={styles.checkboxLabel} htmlFor="privacy-policy">
              Я даю разрешение на{' '}
            </label>
            <a
              href="/files/Maven_privacy_policy_2022.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}>
              использование персональных данных
            </a>
          </div>
        )}
      />
    </>
  );
};

export default FormFields;

import ruLocale from 'date-fns/locale/ru';

export const getYears = (date: Date, years: number) =>
  date.setFullYear(date.getFullYear() - years);

export const localeMap = {
  ru: ruLocale,
};

export const maskMap = {
  ru: '__.__.____',
};

import React, { FC } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import { ControllerFieldState } from 'react-hook-form';
import { LOCALIZER, MAX_YEARS } from './constants';
import { getYears, localeMap, maskMap } from './helper';
import { Nullable } from 'types/common';

import styles from './styles.module.scss';

interface DatePickerProps {
  onChange: (date: Nullable<Date>) => void;
  label: string;
  className?: string;
  value: Nullable<Date> | string;
  fieldState: ControllerFieldState;
  readonly?: boolean;
}

const LocalizedDatePicker: FC<DatePickerProps> = ({
  onChange,
  fieldState,
  label,
  className = '',
  value,
  readonly,
}) => {
  const { error, isDirty } = fieldState;
  const date = new Date();

  const minDate = new Date(getYears(date, MAX_YEARS));

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[LOCALIZER]}>
      <label
        className={clsx(styles.label, {
          [className]: className,
        })}>
        <span className={styles.labelTitle}>{label}</span>
        {error && (
          <p
            className={clsx(styles.errorMessage, {
              [styles.errorMessageShow]: error,
            })}>
            {error.message}
          </p>
        )}
        <DatePicker
          mask={maskMap[LOCALIZER]}
          minDate={minDate}
          value={value}
          maxDate={new Date()}
          openTo="year"
          disabled={readonly}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className={clsx(styles.datePicker, {
                [styles.containerError]: error,
                [styles.valid]: !error && isDirty,
                [styles.disabled]: params.disabled,
              })}
            />
          )}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: { offset: [30, -90] },
              },
            ],
          }}
        />
      </label>
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;

import React from 'react';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { useGetOrganizationQuery, useRegisterMutation } from 'store/api';
import { useSelector } from 'react-redux';
import { OrganizationInfoType } from 'reducers/organizationSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'components/form';
import Button from 'ui-kit/button';
import { REGISTRATION_SERVER_ERRORS, initialRegistration } from '../constants';
import { getRegistrationSchema } from 'utils/schema';
import { ButtonType } from 'ui-kit/button/type';
import {
  RegistrationInputNamesEnum,
  RegistrationValuesType,
} from 'types/registration';
import FormFields from './formFields';

import styles from './registration.module.scss';
import { Links } from 'types/links';

const Registration = () => {
  const { name, id, unrequiredFields } = useSelector<
    RootState,
    OrganizationInfoType
  >(({ organization }) => organization);
  const { data } = useGetOrganizationQuery(name ?? '', {
    skip: !name,
  });

  const [register, { isLoading }] = useRegisterMutation();

  const {
    handleSubmit,
    setError: setFormError,
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: initialRegistration,
    resolver: yupResolver(
      getRegistrationSchema(
        data?.allowedEmailDomains ?? [''],
        unrequiredFields,
      ),
    ),
  });
  const router = useRouter();

  const onSubmitForm = async (data: RegistrationValuesType) => {
    if (isLoading) {
      return;
    }

    if (data) {
      await register({ ...data, id })
        .unwrap()
        .then(() =>
          router.push({
            pathname: Links.alert,
            query: { keyword: 'registration', email: data.email },
          }),
        )
        .catch((error) => {
          if (error.data?.message.includes('Пользователь')) {
            setFormError(RegistrationInputNamesEnum.email, {
              message: REGISTRATION_SERVER_ERRORS.INVALID_EMAIL,
              type: RegistrationInputNamesEnum.email,
            });
          }
          if (error.data?.message.includes('Пароль')) {
            setFormError(RegistrationInputNamesEnum.password, {
              message: REGISTRATION_SERVER_ERRORS.INVALID_PASSWORD,
              type: RegistrationInputNamesEnum.password,
            });
          }
        });
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Регистрация</h1>
      <Form onSubmit={handleSubmit(onSubmitForm)} className={styles.form}>
        <div className={styles.wrapper}>
          <FormFields control={control} />
          <Button
            type={ButtonType.submit}
            loading={isLoading}
            className={styles.button}>
            Зарегистрироваться
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Registration;

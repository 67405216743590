import * as React from 'react';
import { Nullable } from 'types/common';

import styles from './column-view.module.scss';

type ColumnViewProps = {
  left: Nullable<JSX.Element>;
  right: JSX.Element;
};

export const ColumnView: React.FC<ColumnViewProps> = ({ left, right }) => (
  <div className={styles.container}>
    {left}
    {right}
  </div>
);

import React, { FC, forwardRef } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

import clsx from 'clsx';

import DropdownIndicatorComponent from './components/DropdownIndicator';
import NoOptionsMessageComponent from './components/NoOptionsMessage';
import OptionComponent from './components/Option';
import { stylesSelect } from './helper';
import { Option, SelectParams } from './type';

import styles from './select.module.scss';

// TODO Нормально типизировать forwardRef
const SelectComponent: FC<SelectParams> = forwardRef<never, SelectParams>(
  (componentProps: SelectParams, ref) => {
    const {
      disabled = false,
      name = '',
      placeholder = '',
      options,
      label = '',
      onChange,
      onBlur,
      className = '',
      fieldState,
      defaultValue,
    } = componentProps;

    const handleChange = (
      newValue: SingleValue<Option> | MultiValue<Option>,
    ) => {
      if (newValue) {
        const option = newValue as Option;

        onChange(option.value);
      }
    };

    return (
      <label
        className={clsx(styles.label, {
          [className]: className,
          [styles.labelError]: !!fieldState?.error,
          [styles.labelValid]:
            (!fieldState?.error && fieldState?.isDirty) || defaultValue,
        })}>
        <span
          className={clsx(styles.errorMessage, {
            [styles.errorMessageShow]: fieldState?.error,
          })}>
          {fieldState?.error?.message ?? ''}
        </span>

        <span
          className={clsx(styles.labelTitle, {
            [styles.labelTitleDisabled]: disabled,
          })}>
          {label}
        </span>

        <Select
          instanceId={name}
          defaultValue={defaultValue ? defaultValue : undefined}
          isMulti={false}
          ref={ref}
          components={{
            DropdownIndicator: DropdownIndicatorComponent,
            NoOptionsMessage: NoOptionsMessageComponent,
            IndicatorSeparator: null,
            Option: OptionComponent,
          }}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          isDisabled={disabled}
          options={options}
          name={name}
          styles={stylesSelect}
        />
      </label>
    );
  },
);

SelectComponent.displayName = 'Select';

export default SelectComponent;

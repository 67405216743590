import Image from 'next/image';
import Button from 'ui-kit/button';
import { ButtonType } from 'ui-kit/button/type';
import {
  LOGIN_ACTION_GROUP_TITLE,
  LOGIN_BTN_TEXT,
  REGISTRATION_ACTION_GROUP_TITLE,
  REGISTRATION_BTN_TEXT,
} from '../constants';

import mavenLogo from 'public/static/maven-logo.svg';

import styles from './sidebar.module.scss';
import { Links } from 'types/links';
import { useCheckProvidedOrg } from 'hooks/useCheckProvidedOrg';

type SidebarProps = {
  content: 'login' | 'registration';
};

export const Sidebar: React.FC<SidebarProps> = ({ content }: SidebarProps) => {
  return (
    <aside className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Image src={mavenLogo} alt="Maven" unoptimized />
        </div>
        {content === 'login' ? (
          <LoginActionGroup />
        ) : (
          <RegistrationActionGroup />
        )}
      </div>
    </aside>
  );
};

const LoginActionGroup = () => {
  const { registrationAllowed } = useCheckProvidedOrg();

  if (!registrationAllowed) return null;

  return (
    <div className={styles.actionGroup}>
      <p className={styles.title}>{REGISTRATION_ACTION_GROUP_TITLE}</p>
      <Button type={ButtonType.button} href={Links.registration}>
        {REGISTRATION_BTN_TEXT}
      </Button>
    </div>
  );
};

const RegistrationActionGroup = () => (
  <div className={styles.actionGroup}>
    <p className={styles.title}>{LOGIN_ACTION_GROUP_TITLE}</p>
    <Button type={ButtonType.button} href={Links.login}>
      {LOGIN_BTN_TEXT}
    </Button>
  </div>
);
